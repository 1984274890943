/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import { TableCell, TableFooter, TableRow } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import moment from 'moment'
import { TableFilterList } from 'mui-datatables'
import prettyMilliseconds from 'pretty-ms'
import React from 'react'
import { Alert, Badge, Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import InfoIconSvg from '../../images/info-sign.svg'
import BuyerSubmitTenderBidsModal from '../../pages/app/kitchensink/buyerSubmitTenderBidsModal'
import BuyerTenderBiddingHasEnded from '../../pages/app/kitchensink/buyerTenderBiddingHasEnded'
import BuyerUploadBulkBidsModal from '../../pages/app/kitchensink/buyerUploadBulkBidsModal'
import BuyerUploadBulkTenderBidsModal from '../../pages/app/kitchensink/buyerUploadBulkTenderBidsModal'
import {
  useBuyerGetListingLotQuery,
  useGetCountriesQuery,
  useGetCurrenciesQuery,
  useGetListingQuery,
  useGetListingStatusesQuery,
  useSubmitBid
} from '../api'
import { useBuyerGetListingLotsQuery, useGetListingLotSkusQuery, usePlaceSkuBidMutation, useSubmitTenderBidMutation } from '../api/BuyerApi'
import LotNavButtons from '../biddingActivityTabs/LotNavButtons'
import Countdown, { diffMs } from '../Countdown'
import { Status } from '../ListingStatus'
import MUIDataTable from '../MuiDatatableUtils'
import MuiToolbarSubmit from '../MuiToolbarSubmit'
import NotificationToaster from '../NotificationToaster'
import Spinner from '../spinner'
import { isUndefOrNull } from '../utils'
import { useWatchListingSocket, WebSocketStatus } from '../websocket'
import { isBidValid } from './biddingComponents/common'
import PlaceTenderBidCell from './biddingComponents/PlaceTenderBidCell'

/**
 * Verifies whether the given value is a valid bid.
 * @param {string} buyerBid
 * @returns
 */
const isValidBid = (buyerBid) => !isNaN(parseInt(buyerBid)) && parseInt(buyerBid) > 0
const isNullOrUndef = o => typeof o === 'undefined' || o === null
const stringEquals = (a, b) => (a + '') === (b + '')

const isPositiveNumber = o => {
  try {
    if (!isNullOrUndef(o)) {
      const tmp = Math.round(o)
      return tmp > 0
    }
  } catch (err) {
    return false
  }
  return false
}

const CustomChip = ({ label, onDelete }) => {
  return (
        <Chip
            variant="outlined"
            color="secondary"
            label={label}
            onDelete={onDelete}
        />
  )
}

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />
}

/**
 * Convenience cell wrapper to provide listing lot data.
 */
const ListingLotField = ({ listingId, listingLotId, render }) => {
  const { isSuccess, isError, data } = useBuyerGetListingLotQuery({ listingId, listingLotId })
  return <>
    { isError
      ? '-'
      : (isSuccess && data)
          ? render(data)
          : <Spinner />
  }
  </>
}

/**
 * PlaceBid Input Cell.
 */
const PlaceLotBidCell = ({ listingId, listingLotId, data = {} } = {}) => {
  // props
  const listingQuery = useGetListingQuery({ listingId, isVendor: false })
  const { data: { status } = {} } = listingQuery
  const {
    buyer_bid: buyerBid = null,
    end_time: endTime,
    asking_price_enable: askingPriceEnabled = true,
    asking_bid_amount: askingBidAmount = null,
    increment_amount: incrementAmount = 0
  } = data
  // state
  const [bidAmount, setBidAmount] = React.useState('')
  const [submitEnabled, setSubmitEnabled] = React.useState(false)
  const [formEnabled, setFormEnabled] = React.useState(status === Status.open)
  const [disabledMessage, setDisabledMessage] = React.useState('')
  const [showConfirmBidModal, setShowConfirmBidModal] = React.useState(false)
  const confirmBidButtonRef = React.useRef()

  // watch for updates to status
  React.useEffect(() => {
    setFormEnabled(ps => {
      if (ps === true) {
        return status === Status.open
      } else {
        return false
      }
    })
  }, [status])

  // check if we should disable form if listing has ended...
  React.useEffect(() => {
    // do immediate check to see if timer should be disabled...
    const deltaMs = diffMs(new Date(endTime))
    if (deltaMs < 0) {
      console.debug('timer reached #3, disabling form...')
      setBidAmount('')
      setSubmitEnabled(false)
      setFormEnabled(false)
      setDisabledMessage('Listing Lot has ended')
    } else {
      // otherwise clear timer in future...
      const timer = setTimeout(() => {
        console.debug('timer reached #4, disabling form...')
        setBidAmount('')
        setSubmitEnabled(false)
        setFormEnabled(false)
        setDisabledMessage('Listing Lot has ended')
      }, deltaMs)
      return () => clearTimeout(timer)
    }
  }, [endTime])

  // focus ConfirmBid button when shown...
  React.useEffect(() => {
    if (showConfirmBidModal) confirmBidButtonRef.current.focus()
  }, [showConfirmBidModal])

  // if bidAmount is blank (e.g. post-submit), make sure submit is disabled!
  React.useEffect(() => {
    if (bidAmount === '') {
      setSubmitEnabled(false)
      setDisabledMessage('Please provide a bid')
    }
  }, [bidAmount])

  // on update field...
  const updateField = value => {
    // if blank, allow updating the value (and disable Bid button)...
    if (value.trim().length === 0) {
      setBidAmount(value)
      setSubmitEnabled(false)
      setDisabledMessage('Please provide a bid')

      // if value is a valid number, allow updating the value...
    } else if (Math.round(value) > 0) {
      const newBidAmount = Math.round(value)
      setBidAmount(newBidAmount)
      // check if new bid is valid...
      if (askingPriceEnabled) {
        const newBidIsEqualToOrGreaterThanAskingPrice = newBidAmount >= (Math.round(askingBidAmount) + Math.round(incrementAmount))
        setSubmitEnabled(newBidIsEqualToOrGreaterThanAskingPrice)
        setDisabledMessage(newBidIsEqualToOrGreaterThanAskingPrice
          ? ''
          : `New Bid Amount must be greater than or equal to $${(Math.round(askingBidAmount) + Math.round(incrementAmount)).toLocaleString()}.`
        )
      } else {
        const newBidIsGreaterThanPrevious = newBidAmount > Math.round(buyerBid)
        setSubmitEnabled(newBidIsGreaterThanPrevious)
        setDisabledMessage(newBidIsGreaterThanPrevious
          ? ''
          : `New Bid Amount must be greater than previous bid $${(Math.round(buyerBid)).toLocaleString()}.`
        )
      }
    }
  }

  // auctions
  const submitAuctionBidMutation = useSubmitBid()

  return <>
    <InputGroup>
      <InputGroup.Prepend>
        <InputGroup.Text style={{ height: '29px' }}>{listingQuery.data.currency.toUpperCase()}</InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control
        size="sm"
        type="text"
        value={bidAmount}
        disabled={!formEnabled}
        onChange={e => updateField(e.target.value)}
        onKeyUp={e => {
          if (e.keyCode === 13 && submitEnabled) {
            setShowConfirmBidModal(true)
          }
        }} // submit on enter
        className='placebid'
        onFocus={e => e.target.select()} // select all text on focus
        placeholder={formEnabled ? 'Enter bid' : '' }
        inputMode="numeric"
      />
      <InputGroup.Append>
        <Button
          size="sm"
          title={disabledMessage}
          disabled={!submitEnabled || !formEnabled}
          onClick={() => {
            submitAuctionBidMutation.mutate({ listingId, listingLotId, bidAmount })
            setBidAmount('')
          }}
        >Bid</Button>
      </InputGroup.Append>
    </InputGroup>

    {/* Confirm Bid Modal */}
    <Modal show={showConfirmBidModal} onHide={() => setShowConfirmBidModal(false)}>
        <Modal.Body>
            <h5 className="m-0 mb-3"><img src={InfoIconSvg} className="mr-3" />Submit bid?</h5>
            <div className="d-flex justify-content-between mt-5">
                <Button type="button" onClick={() => setShowConfirmBidModal(false)} variant="outline-primary">Close</Button>
                <Button type="button" ref={confirmBidButtonRef} onClick={() => {
                  submitAuctionBidMutation.mutate({ listingId, listingLotId, bidAmount })
                  setBidAmount('')
                  setShowConfirmBidModal(false)
                }} variant="primary">Submit</Button>
            </div>
        </Modal.Body>
    </Modal>
  </>
}

/**
 * PlaceBid Input Cell.
 */
const PlaceSkuBidCell = ({ listingId, listingLotId, listingLotSkuId, data = {} } = {}) => {
  // props
  const listingQuery = useGetListingQuery({ listingId, isVendor: false })
  const { data: { status } = {} } = listingQuery
  const {
    buyer_bid: buyerBid = null,
    end_time: endTime,
    asking_price_enable: askingPriceEnabled = true,
    asking_bid_amount: askingBidAmount = null,
    increment_amount: incrementAmount = 0
  } = data

  // state
  const [bidAmount, setBidAmount] = React.useState('')
  const [submitEnabled, setSubmitEnabled] = React.useState(false)
  const [formEnabled, setFormEnabled] = React.useState(status === Status.open)
  const [disabledMessage, setDisabledMessage] = React.useState('')
  const [showConfirmBidModal, setShowConfirmBidModal] = React.useState(false)
  const confirmBidButtonRef = React.useRef()

  // query
  const doPlaceSkuBidMutation = usePlaceSkuBidMutation()

  // watch for updates to status
  React.useEffect(() => {
    setFormEnabled(ps => {
      if (ps === true) {
        return status === Status.open
      } else {
        return false
      }
    })
  }, [status])

  // check if we should disable form if listing has ended...
  React.useEffect(() => {
    // do immediate check to see if timer should be disabled...
    const deltaMs = diffMs(new Date(endTime))
    if (deltaMs < 0) {
      console.debug('timer reached #3, disabling form...')
      setBidAmount('')
      setSubmitEnabled(false)
      setFormEnabled(false)
      setDisabledMessage('Listing Lot has ended')
    } else {
      // otherwise clear timer in future...
      const timer = setTimeout(() => {
        console.debug('timer reached #4, disabling form...')
        setBidAmount('')
        setSubmitEnabled(false)
        setFormEnabled(false)
        setDisabledMessage('Listing Lot has ended')
      }, deltaMs)
      return () => clearTimeout(timer)
    }
  }, [endTime])

  // focus ConfirmBid button when shown...
  React.useEffect(() => {
    if (showConfirmBidModal) confirmBidButtonRef.current.focus()
  }, [showConfirmBidModal])

  // if bidAmount is blank (e.g. post-submit), make sure submit is disabled!
  React.useEffect(() => {
    if (bidAmount === '') {
      setSubmitEnabled(false)
      setDisabledMessage('Please provide a bid')
    }
  }, [bidAmount])

  // on update field...
  const updateField = value => {
    // if blank, allow updating the value (and disable Bid button)...
    if (value.trim().length === 0) {
      setBidAmount(value)
      setSubmitEnabled(false)
      setDisabledMessage('Please provide a bid')

      // if value is a valid number, allow updating the value...
    } else if (Math.round(value) > 0) {
      const newBidAmount = Math.round(value)
      setBidAmount(newBidAmount)
      // check if new bid is valid...
      if (askingPriceEnabled) {
        const newBidIsEqualToOrGreaterThanAskingPrice = newBidAmount >= (Math.round(askingBidAmount) + Math.round(incrementAmount))
        setSubmitEnabled(newBidIsEqualToOrGreaterThanAskingPrice)
        setDisabledMessage(newBidIsEqualToOrGreaterThanAskingPrice
          ? ''
          : `New Bid Amount must be greater than or equal to $${(Math.round(askingBidAmount) + Math.round(incrementAmount)).toLocaleString()}.`
        )
      } else {
        const newBidIsGreaterThanPrevious = newBidAmount > Math.round(buyerBid)
        setSubmitEnabled(newBidIsGreaterThanPrevious)
        setDisabledMessage(newBidIsGreaterThanPrevious
          ? ''
          : `New Bid Amount must be greater than previous bid $${(Math.round(buyerBid)).toLocaleString()}.`
        )
      }
    }
  }

  return <>
    <InputGroup>
      <InputGroup.Prepend>
        <InputGroup.Text style={{ height: '29px' }}>{listingQuery.data.currency.toUpperCase()}</InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control
        size="sm"
        type="text"
        value={bidAmount}
        disabled={!formEnabled}
        onChange={e => updateField(e.target.value)}
        onKeyUp={e => {
          if (e.keyCode === 13 && submitEnabled) {
            setShowConfirmBidModal(true)
          }
        }} // submit on enter
        className='placebid'
        onFocus={e => e.target.select()} // select all text on focus
        placeholder={formEnabled ? 'Enter unit bid' : '' }
        inputMode="numeric"
      />
      <InputGroup.Append>
        <Button
          size="sm"
          title={disabledMessage}
          disabled={!submitEnabled || !formEnabled}
          onClick={() => {
            doPlaceSkuBidMutation.mutate({ listingId, listingLotId, listingLotSkuId, bidAmount })
            setBidAmount('')
          }}
        >Bid</Button>
      </InputGroup.Append>
    </InputGroup>

    {/* Confirm Bid Modal */}
    <Modal show={showConfirmBidModal} onHide={() => setShowConfirmBidModal(false)}>
        <Modal.Body>
            <h5 className="m-0 mb-3"><img src={InfoIconSvg} className="mr-3" />Submit bid?</h5>
            <div className="d-flex justify-content-between mt-5">
                <Button type="button" onClick={() => setShowConfirmBidModal(false)} variant="outline-primary">Close</Button>
                <Button type="button" ref={confirmBidButtonRef} onClick={() => {
                  doPlaceSkuBidMutation.mutate({ listingId, listingLotId, listingLotSkuId, bidAmount })
                  setBidAmount('')
                  setShowConfirmBidModal(false)
                }} variant="primary">Submit</Button>
            </div>
        </Modal.Body>
    </Modal>
  </>
}

/**
 * Display Logic:
 *
 * Asking Bid - show button if...
 *  - anyone has made a bid
 *
 * Quick Bid - show button if...
 *  - the current buyer has made a bid
 */

/**
 * QuickBid or AskingBid Button
 */
const QuickAskingLotBidCell = ({ listingId, listingLotId, data = {} } = {}) => {
  // react-query
  const submitBidMutation = useSubmitBid()
  const listingQuery = useGetListingQuery({ listingId, isVendor: false })
  const { data: { status } = {} } = listingQuery
  // react-state
  const [formEnabled, setFormEnabled] = React.useState(status === Status.open)
  const [nextBid, setNextBid] = React.useState(0)
  const [hideButton, setHideButton] = React.useState(false)
  const [endTime, setEndTime] = React.useState(null)
  const [askingPriceEnabled, setAskingPriceEnabled] = React.useState(null)
  const [incrementAmount, setIncrementAmount] = React.useState(null)

  // watch for updates to status
  React.useEffect(() => {
    setFormEnabled(ps => {
      if (ps === true) {
        return status === Status.open
      } else {
        return false
      }
    })
  }, [status])

  React.useEffect(() => {
    const {
      asking_price_enable = true,
      buyer_bid = null,
      asking_bid_amount = null,
      increment_amount = null,
      end_time
    } = data

    // parse data...
    const tmpBuyerBid = isPositiveNumber(buyer_bid) ? Math.round(buyer_bid) : 0
    const tmpAskingBid = isPositiveNumber(asking_bid_amount) ? Math.round(asking_bid_amount) : 0
    const tmpIncrementAmount = isPositiveNumber(increment_amount) ? Math.round(increment_amount) : 0

    // store data...
    setIncrementAmount(tmpIncrementAmount)
    setEndTime(end_time)
    setAskingPriceEnabled(asking_price_enable)

    if (asking_price_enable) {
      // asking bid
      setNextBid(tmpAskingBid === 0 ? 0 : (tmpAskingBid + tmpIncrementAmount))
      setHideButton(tmpAskingBid === 0)
    } else {
      // quick bid
      setNextBid(tmpBuyerBid === 0 ? 0 : (tmpBuyerBid + tmpIncrementAmount))
      setHideButton(tmpBuyerBid === 0)
    }
  }, [data])

  // check if we should disable form if listing has ended...
  React.useEffect(() => {
    // do immediate check to see if timer should be disabled...
    if (endTime !== null) {
      const deltaMs = diffMs(new Date(endTime))
      if (deltaMs < 0) {
        console.debug('timer reached #5, disabling form...')
        setFormEnabled(false)
        setHideButton(true)
      } else {
        // otherwise clear timer in future...
        const timer = setTimeout(() => {
          console.debug('timer reached #6, disabling form...')
          setFormEnabled(false)
          setHideButton(true)
        }, deltaMs)
        return () => clearTimeout(timer)
      }
    }
  }, [endTime, setHideButton])

  // N.B. Only show QuickBid / AskingBid button, if user has already made a bid.
  return (<>
    {
      hideButton
        ? null
        : <Button
      disabled={!formEnabled}
      variant="outline-primary"
      title={`New Bid Amount: $ ${nextBid.toLocaleString()}`}
      size="sm"
      onClick={() => submitBidMutation.mutate({ listingId, listingLotId, bidAmount: nextBid })}
      >
        { askingPriceEnabled ? `Bid $${nextBid.toLocaleString()}` : `+ $${Math.round(incrementAmount).toLocaleString()}`}
    </Button>
    }
  </>)
}

/**
 * Display Logic:
 *
 * Asking Bid - show button if...
 *  - anyone has made a bid
 *
 * Quick Bid - show button if...
 *  - the current buyer has made a bid
 */

/**
 * ANCHOR QuickBid or AskingBid Button
 */
const QuickAskingBidSkuCell = ({ sku = {} } = {}) => {
  // props
  const { listingId, listingLotId, listingLotSkuId } = sku

  // query
  const doPlaceSkuBidMutation = usePlaceSkuBidMutation()
  const listingQuery = useGetListingQuery({ listingId, isVendor: false })
  const { data: { status } = {} } = listingQuery

  // state
  const [formEnabled, setFormEnabled] = React.useState(status === Status.open)
  const [nextBid, setNextBid] = React.useState(0)
  const [hideButton, setHideButton] = React.useState(false)
  const [endTime, setEndTime] = React.useState(null)
  const [askingPriceEnabled, setAskingPriceEnabled] = React.useState(null)
  const [incrementAmount, setIncrementAmount] = React.useState(null)

  // watch for updates to status
  React.useEffect(() => {
    setFormEnabled(ps => {
      if (ps === true) {
        return status === Status.open
      } else {
        return false
      }
    })
  }, [status])

  React.useEffect(() => {
    const {
      asking_price_enable = true,
      buyer_bid = null,
      asking_bid_amount = null,
      increment_amount = null,
      end_time
    } = sku

    // parse data...
    const tmpBuyerBid = isPositiveNumber(buyer_bid) ? Math.round(buyer_bid) : 0
    const tmpAskingBid = isPositiveNumber(asking_bid_amount) ? Math.round(asking_bid_amount) : 0
    const tmpIncrementAmount = isPositiveNumber(increment_amount) ? Math.round(increment_amount) : 0
    // console.debug('QuickAskingBidCell', { tmpBuyerBid, tmpAskingBid, tmpIncrementAmount })

    // store data...
    setIncrementAmount(tmpIncrementAmount)
    setEndTime(end_time)
    setAskingPriceEnabled(asking_price_enable)

    if (asking_price_enable) {
      setNextBid(tmpAskingBid === 0 ? 0 : (tmpAskingBid + tmpIncrementAmount))
      setHideButton(tmpAskingBid === 0)
    } else {
      setNextBid(tmpBuyerBid === 0 ? 0 : (tmpBuyerBid + tmpIncrementAmount))
      setHideButton(tmpBuyerBid === 0)
    }
  }, [sku])

  // check if we should disable form if listing has ended...
  React.useEffect(() => {
    // do immediate check to see if timer should be disabled...
    if (endTime !== null) {
      const deltaMs = diffMs(new Date(endTime))
      if (deltaMs < 0) {
        console.debug('timer reached #5, disabling form...')
        setFormEnabled(false)
        setHideButton(true)
      } else {
        // otherwise clear timer in future...
        const timer = setTimeout(() => {
          console.debug('timer reached #6, disabling form...')
          setFormEnabled(false)
          setHideButton(true)
        }, deltaMs)
        return () => clearTimeout(timer)
      }
    }
  }, [endTime, setHideButton])

  // N.B. Only show QuickBid / AskingBid button, if user has already made a bid.
  return (<>
    {
      hideButton
        ? null
        : <Button
      disabled={!formEnabled}
      variant="outline-primary"
      title={`New Bid Amount: $ ${nextBid.toLocaleString()}`}
      size="sm"
      onClick={() => doPlaceSkuBidMutation.mutate({ listingId, listingLotId, listingLotSkuId, bidAmount: nextBid })}
      >
        { askingPriceEnabled ? `Bid $${nextBid.toLocaleString()}` : `+ $${Math.round(incrementAmount).toLocaleString()}`}
    </Button>
    }
  </>)
}

/**
 * Returns true if awardLevelSelection is 'award_sku.
 */
const isAwardLevelSelectionASku = (awardLevelSelection) => awardLevelSelection === 'award_sku'
const isAuctionTypeSelectionATender = (saleTypeSelection) => saleTypeSelection === 'tender'

/*
 *
 * ANCHOR Page
 *
 */
const Page = (props) => {
  // ANCHOR props
  const { listingId } = props

  // ANCHOR state
  const [wsstatus, setWsstatus] = React.useState('connecting')
  const [selectedLotId, setSelectedLotId] = React.useState('all')
  const [selectedLot, setSelectedLot] = React.useState({})
  const [showBulkBidModal, setShowBulkBidModal] = React.useState(false)
  const [showTenderBulkBidModal, setShowTenderBulkBidModal] = React.useState(false)
  const [showBiddingHasEnded, setShowBiddingHasEnded] = React.useState(false)
  const [showSubmitTenderBidModal, setShowSubmitTenderBidModal] = React.useState(false)
  const [formEnabled, setFormEnabled] = React.useState(true)
  const [isShowToaster, setIsShowToaster] = React.useState(false)
  const [acceptTerms, setAcceptTerms] = React.useState(false)
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  // ANCHOR query
  const listingQuery = useGetListingQuery({ listingId, isVendor: false })
  const {
    isLoading = false,
    data: {
      status = null,
      global_listing_number = 1,
      end_time: endTime = null,
      listing_lots: listingLots = [],
      award_level_selection: awardLevelSelection = null,
      sale_type_selection: saleTypeSelection = null,
      bids_mandatory_selection = null
    } = {}
  } = listingQuery
  const isSkuAuction = isLoading === false && awardLevelSelection !== null && isAwardLevelSelectionASku(awardLevelSelection)
  const isTender = isLoading === false && saleTypeSelection !== null && isAuctionTypeSelectionATender(saleTypeSelection)
  const listingLotSkusQuery = useGetListingLotSkusQuery({ listingId, listingLotId: selectedLotId, listingLots, isVendor: false, enabled: true })
  const { data: { listing_lot: listingLot = {} } = {} } = listingLotSkusQuery
  const { lot_number = -1, listing_lot_skus: listingLotSkus = [] } = listingLot
  // listing lots query get all data for all the lots and store in a array.
  const listingLotsQuery = useBuyerGetListingLotsQuery({ listingId, listingLots })
  const statusesQuery = useGetListingStatusesQuery()
  const countriesQuery = useGetCountriesQuery()
  const currenciesQuery = useGetCurrenciesQuery()
  const listingIsOpen = status === 'open'

  const submitTenderBidMutation = useSubmitTenderBidMutation()
  React.useEffect(() => {
    if (submitTenderBidMutation.isSuccess) {
      setShowTenderBulkBidModal(false)
      setIsShowToaster(true)
      setFormSubmitted(false)
      setAcceptTerms(false)
    }
  }, [submitTenderBidMutation.isSuccess])

  React.useEffect(() => {
    setShowBiddingHasEnded(isTender && !listingIsOpen)
  }, [isTender, listingIsOpen])

  const getSkuRow = (meta) => {
    return listingLotSkus[meta.currentTableData[meta.rowIndex].index]
  }

  // connect for socket updates...
  useWatchListingSocket({ listingId, handleStatusUpdate: setWsstatus, isVendor: false })

  // check if we should disable form if listing has ended...
  React.useEffect(() => {
    // do immediate check to see if timer should be disabled...
    if (endTime !== null) {
      const deltaMs = diffMs(new Date(endTime))
      if (deltaMs < 0) {
        console.debug('timer reached #5, disabling form...')
        setFormEnabled(false)
      } else {
        // otherwise clear timer in future...
        const timer = setTimeout(() => {
          console.debug('timer reached #6, disabling form...')
          setFormEnabled(false)
        }, deltaMs)
        return () => clearTimeout(timer)
      }
    }
  }, [endTime, setFormEnabled])

  // ANCHOR LOT columns
  const columnsLot = [
    listingQuery.isSuccess && listingQuery.data.time_extension_selection === 'enabled'
      ? {
          label: 'Time Remaining',
          name: 'id',
          options: {
            customBodyRender: (listingLotId) => <ListingLotField
          listingId={listingId}
          listingLotId={listingLotId}
          render={data => <Countdown
            className="text-nowrap"
            toDate={new Date(data.end_time)}
            showOnEnd=''
          />}
        />
          }
        }
      : null,
    // { label: 'Id', name: 'id' },
    { label: 'Lot', name: 'lot_number' },
    { label: 'Description', name: 'lot_description' },
    {
      label: 'Quantity',
      name: 'id',
      options: {
        customBodyRender: (listingLotId) => <ListingLotField
          listingId={listingId}
          listingLotId={listingLotId}
          render={data => (data.quantity).toLocaleString() }
        />
      }
    },
    {
      label: 'Your Bid',
      name: 'id',
      options: {
        customBodyRender: (listingLotId) => <ListingLotField
          listingId={listingId}
          listingLotId={listingLotId}
          render={data => <>
            <div className={`bidstatusindicator bidstatusindicator-${data.colour_indicator} text-right`}
            >{!isUndefOrNull(data.buyer_bid) ? ('$' + Math.round(data.buyer_bid).toLocaleString()) : ''}</div>
           </>}
        />
      }
    },
    {
      label: 'Place Bid',
      name: 'id',
      options: {
        customBodyRender: (listingLotId) => <ListingLotField
          listingId={listingId}
          listingLotId={listingLotId}
          render={data => <PlaceLotBidCell listingId={listingId} listingLotId={listingLotId} data={data} />}
        />
      }
    },
    {
      label: (listingQuery.isSuccess && listingQuery.data.show_asking_bid_selection === 'show_asking_bid_yes' ? 'Asking Bid' : 'Quick Bid'),
      name: 'id',
      options: {
        customBodyRender: (listingLotId) => <ListingLotField
          listingId={listingId}
          listingLotId={listingLotId}
          render={data => <QuickAskingLotBidCell listingId={listingId} listingLotId={listingLotId} data={data} />}
        />
      }
    }
  ].filter(f => f !== null)

  // ANCHOR SKU columns
  const columnsSku = [
    listingQuery.isSuccess && listingQuery.data.time_extension_selection === 'enabled'
      ? {
          label: 'End Time',
          name: 'listing_lot_sku_id',
          options: {
            customBodyRender: (x, meta) => {
              const data = getSkuRow(meta)
              if (data.end_time) {
                return <Countdown
                  className="text-nowrap"
                  toDate={new Date(data.end_time)}
                  showOnEnd=''
                />
              } else {
                return 'No End Time?'
              }
            }
          }
        }
      : null,
    // { label: 'Id', name: 'id' },
    {
      label: 'Lot',
      name: 'listing_lot_number'
    },
    {
      label: 'Description',
      name: 'sku_description'
      // options: {
      //   customBodyRender: (val, meta) => {
      //     const data = getSkuRow(meta)
      //     return skuToDescription(data)
      //   }
      // }
    },
    { label: 'Make', name: 'make', options: { display: false } },
    { label: 'Model', name: 'model', options: { display: false } },
    { label: 'Memory', name: 'memory', options: { display: false } },
    { label: 'Colour', name: 'colour', options: { display: false } },
    { label: 'Carrier', name: 'carrier', options: { display: false } },
    { label: 'Id lock', name: 'id_lock', options: { display: false } },
    { label: 'Kit', name: 'kit', options: { display: false } },
    { label: 'Region lock', name: 'region_lock', options: { display: false } },
    { label: 'Sim lock', name: 'sim_lock', options: { display: false } },
    { label: 'Grade', name: 'grade' },
    { label: 'Quantity', name: 'qty' },
    {
      label: 'Your Bid',
      name: 'listing_lot_sku_id',
      options: {
        customBodyRender: (x, meta) => {
          const data = getSkuRow(meta)
          return <>
            <div className={`bidstatusindicator bidstatusindicator-${data.colour_indicator} text-right`}
            >{!isUndefOrNull(data.buyer_bid) && data.buyer_bid !== 0 ? ('$' + Math.round(data.buyer_bid).toLocaleString()) : ''}</div>
          </>
        }
      }
    },
    {
      label: 'Place Bid ',
      name: 'listing_lot_sku_id',
      options: {
        customBodyRender: (x, meta) => {
          const sku = getSkuRow(meta)
          return <PlaceSkuBidCell listingId={sku.listingId} listingLotId={sku.listingLotId} listingLotSkuId={sku.listingLotSkuId} data={sku} />
        }
      }
    },
    {
      label: (listingQuery.isSuccess && listingQuery.data.show_asking_bid_selection === 'show_asking_bid_yes' ? 'Asking Bid' : 'Quick Bid'),
      name: 'listing_lot_sku_id',
      options: {
        customBodyRender: (x, meta) => <QuickAskingBidSkuCell sku={getSkuRow(meta)} />
      }
    }
  ].filter(f => f !== null)

  // ANCHOR TENDER columns

  const [tenderBidsHaveChanges, setTenderBidsHaveChanges] = React.useState(false)
  const [tenderBidsAreValid, setTenderBidsAreValid] = React.useState(false)
  const [tenderBids, setTenderBids] = React.useState({}) // NOTE Map of SkuId to Price

  /**
   * When a bid is updated from the input textbox (but not submitted)...
   * @param {string} lotSkuId
   * @param {string} bidAmount
   */
  const onBidUpdated = (lotId, skuId, bidAmount) => {
    console.log('setting bid amount', { skuId, bidAmount })
    setTenderBids(ps => ({ ...ps, [skuId]: bidAmount }))
  }

  // update bids whenever the backend skus change...
  React.useEffect(() => {
    if (isTender) {
      // Task #2b - instead of replacing tenderBids, we'll add to tender bids (N.B. check the state is cleared between listings)
      setTenderBids(prev => {
        listingLotSkus.forEach(sku => {
          if (!isValidBid(prev[sku.id])) {
            prev[sku.id] = sku.buyer_bid
          }
        })
        return prev
      })
    }
  }, [listingLotSkus, isTender])

  // whenever tenderbids changes, check that all the prices are valid...
  React.useEffect(() => {
    // Task #1 disable submit button, if the new tenderBids values are NOT different from the listingLotSkus*.buyer_bid values
    if (bids_mandatory_selection === 'bids_mandatory_yes') {
      setTenderBidsAreValid(listingLotSkus.every(sku => isBidValid(tenderBids[sku.id])))
    } else {
      setTenderBidsAreValid(true)
    }

    // keep track of whether there are any changes to the bids...
    setTenderBidsHaveChanges(listingLotSkus.some(sku => {
      const newBid = tenderBids[sku.id]
      const isValid = isBidValid(newBid)
      const changed = isValid && !stringEquals(sku.buyer_bid, newBid)
      // console.debug(`tender sku changed? - ${JSON.stringify([sku.id, sku.buyer_bid, newBid, isValid, changed])}`)
      return changed
    }))
  }, [bids_mandatory_selection, tenderBids, listingLotSkus, selectedLotId]) // N.B. re-check when selectedLotId changes!

  const columnsTender = [
    {
      label: 'Lot',
      name: 'lot_number'
    },
    {
      label: 'Description',
      name: 'sku_description'
      // options: {
      //   customBodyRender: (val, meta) => {
      //     const data = getSkuRow(meta)
      //     return skuToDescription(data)
      //   }
      // }
    },
    { label: 'Make', name: 'make', options: { display: false } },
    { label: 'Model', name: 'model', options: { display: false } },
    { label: 'Memory', name: 'memory', options: { display: false } },
    { label: 'Colour', name: 'colour', options: { display: false } },
    { label: 'Carrier', name: 'carrier', options: { display: false } },
    { label: 'Id lock', name: 'id_lock', options: { display: false } },
    { label: 'Kit', name: 'kit', options: { display: false } },
    { label: 'Region lock', name: 'region_lock', options: { display: false } },
    { label: 'Sim lock', name: 'sim_lock', options: { display: false } },
    { label: 'Grade', name: 'grade' },
    { label: 'Quantity', name: 'qty' },
    {
      label: 'Place Bid ',
      name: 'listing_lot_sku_id',
      options: {
        customBodyRender: (listing_lot_sku_id, meta) => {
          const sku = getSkuRow(meta)
          let buyer_bid = tenderBids[listing_lot_sku_id]
          if (buyer_bid === '' || buyer_bid === 0) {
            buyer_bid = null
          }
          return <PlaceTenderBidCell
            listingId={sku.listingId}
            listingLotId={sku.listingLotId}
            listingLotSkuId={sku.listingLotSkuId}
            currency={listingQuery.data.currency.toUpperCase()}
            listingStatus={status}
            onBidUpdated={onBidUpdated}
            buyerBid={ !isUndefOrNull(buyer_bid) ? buyer_bid : sku.buyer_bid !== '' && sku.buyer_bid !== 0 ? sku.buyer_bid : ''}
            endTime={sku.end_time}
            />
        }
      }
    },
    {
      label: 'Subtotal',
      name: 'listing_lot_sku_id',
      options: {
        customBodyRender: (listing_lot_sku_id, meta) => {
          const sku = getSkuRow(meta)
          let buyer_bid = tenderBids[listing_lot_sku_id]
          if (buyer_bid === '' || buyer_bid === 0) {
            buyer_bid = null
          }
          return <>
            <div className="text-right">{ !isUndefOrNull(sku.quantity) && sku.quantity !== 0 ? !isUndefOrNull(buyer_bid) ? ('$' + Math.round(buyer_bid * sku.quantity).toLocaleString()) : !isUndefOrNull(sku.buyer_bid) && sku.buyer_bid !== 0 && sku.buyer_bid !== '' ? ('$' + Math.round(sku.buyer_bid * sku.quantity).toLocaleString()) : '' : ''}</div>
          </>
        }
      }
    }
  ].filter(f => f !== null)

  return <>
        <div className="card-body rounded-soft bg-white">

          { process.env.NODE_ENV !== 'production' && <div>{selectedLotId} - tenderBidsAreValid?={tenderBidsAreValid ? 'YES' : 'NO'}</div> }
          { process.env.NODE_ENV !== 'production' && <div>{selectedLotId} - tenderBidsHaveChanges?={tenderBidsHaveChanges ? 'YES' : 'NO'}</div> }

          {/* sku auction */}
          { !isTender && isSkuAuction && <LotNavButtons
            listingQuery={listingQuery}
            selectedLotId={selectedLotId}
            setSelectedLotId={setSelectedLotId}
            setSelectedLot={setSelectedLot}
          />}

          {/* NOTE lot auction - does not have lot buttons */}
                    {/* lot tender */}

          { isTender && <Row>
            <Col xs={2} lg={1}> <Button size="sm" variant="outline-primary" style={{ marginTop: '4px' }} className="ml-3" onClick={() => setShowTenderBulkBidModal(true)}>Bulk Bid</Button> </Col>
            <Col xs={{ span: 10 }} lg={11} > <LotNavButtons

            listingQuery={listingQuery}
            selectedLotId={selectedLotId}
            setSelectedLotId={setSelectedLotId}
            setSelectedLot={setSelectedLot}
            showAll={false}
          /></Col>
            </Row>}
            {isTender && <hr style={{ marginTop: '0px', marginLeft: '-20px', marginRight: '-20px' }}/>}

          {/* web socket status */}
          <WebSocketStatus status={wsstatus} />

          { isTender && listingLot.unit_price_submission_date && <div className="container-fluid d-flex justify-content-end mb-3">
            <Badge pill variant="outline-dark" className="float-right">Last Submitted: {moment(listingLot.unit_price_submission_date).format('YY-MM-DD HH:mm')}</Badge>
          </div>
          }

          {/* show spinner if loading, otherwise show content */}
          { listingQuery.isLoading || statusesQuery.isLoading || countriesQuery.isLoading || currenciesQuery.isLoading
            ? <div className="d-flex justify-content-center p-5"><Spinner /></div>
            : listingQuery.isError
              ? (<Alert variant="danger" className="my-4">{listingQuery.error.message}</Alert>)
              : listingQuery.isSuccess && (
              <div className="container-fluid mb-4">

                {/* summary table */}

                <div className="mt-3">
                  {/* ANCHOR LOT table */}
                  { !isTender && !isSkuAuction && listingQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                  { !isTender && !isSkuAuction && listingQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                  { !isTender && !isSkuAuction && !isTender && listingQuery.isSuccess && <MUIDataTable
                          data={listingQuery.data.listing_lots}
                          columns={columnsLot}
                          options={{
                            selectableRows: 'none',
                            pagination: true,
                            jumpToPage: false,
                            rowsPerPage: 15,
                            print: false,
                            search: true,
                            download: true,
                            sort: false,
                            filter: true,
                            viewColumns: true,
                            elevation: 0,
                            components: {
                              TableFilterList: CustomFilterList
                            },
                            setTableProps: () => ({ size: 'small' }),
                            onDownload: (buildHead, buildBody, columns, data) => {
                              // add prices and totals...

                              listingQuery.data.listing_lots.forEach((lotData, index) => {
                                // Get You bid from listingLotsQuery which have all lots' data
                                let yourBid
                                if (listingLotsQuery.isSuccess) {
                                  yourBid = listingLotsQuery.data[index].buyer_bid
                                }
                                const tmp = data[index].data
                                // transfer the remaining time format to a readable one. and replace the first column.
                                if (listingQuery.isSuccess && listingQuery.data.time_extension_selection === 'enabled') {
                                  const timeRemaining = new Date(listingQuery.data.end_time)
                                  const sencondsRemaining = parseInt((timeRemaining.getTime() - Date.now()) / 1000) * 1000
                                  // replace the first column with time remaining
                                  tmp[0] = prettyMilliseconds(sencondsRemaining)
                                }
                                tmp[tmp.length - 4] = lotData.quantity
                                tmp[tmp.length - 3] = yourBid
                                tmp[tmp.length - 2] = ' - '
                                tmp[tmp.length - 1] = ' - '
                              })
                              return '\uFEFF' + buildHead(columns) + buildBody(data)
                            }
                          }}
                          />
                  }

                  {/* ANCHOR SKU table */}
                  { !isTender && isSkuAuction && listingLotSkusQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                  { !isTender && isSkuAuction && listingLotSkusQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                  { !isTender && isSkuAuction && listingLotSkusQuery.isSuccess && <MUIDataTable
                      data={listingLotSkus}
                      columns={columnsSku}
                      options={{
                        selectableRows: 'none',
                        pagination: true,
                        jumpToPage: false,
                        rowsPerPage: 100,
                        print: false,
                        search: true,
                        download: true,
                        sort: false,
                        filter: true,
                        viewColumns: true,
                        elevation: 0,
                        components: {
                          TableFilterList: CustomFilterList
                        },
                        customToolbar: selectedRows => {
                          if (listingIsOpen) {
                            return <Button size="sm" disabled={!formEnabled} variant="outline-primary" className="ml-3" onClick={() => setShowBulkBidModal(true)}>Bulk Bid</Button>
                          }
                        },
                        setTableProps: () => ({ size: 'small' }),
                        onDownload: (buildHead, buildBody, columns, data) => {
                        // add prices and totals...
                          listingLotSkus.forEach((sku, index) => {
                          // copy #2
                            const tenderBid = tenderBids[sku.id]
                            const bid = isValidBid(tenderBid) ? parseInt(tenderBid) : sku.buyer_bid
                            const subtotal = (sku.qty * bid)
                            const tmp = data[index].data
                            if (listingQuery.isSuccess && listingQuery.data.time_extension_selection === 'enabled') {
                              const timeRemaining = new Date(listingQuery.data.end_time)
                              const sencondsRemaining = parseInt((timeRemaining.getTime() - Date.now()) / 1000) * 1000
                              // replace the first column with time remaining
                              tmp[0] = prettyMilliseconds(sencondsRemaining)
                            }
                            tmp[tmp.length - 3] = bid
                            tmp[tmp.length - 2] = ' - '
                            tmp[tmp.length - 1] = ' - '
                          })
                          return '\uFEFF' + buildHead(columns) + buildBody(data)
                        }
                      }
                    }
                      />
                  }

                  {/* ANCHOR Tender table */}

                  { isTender && listingLotSkusQuery.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                  { isTender && listingLotSkusQuery.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'' + listingLotSkusQuery.error.message}</Alert> }
                  { isTender && listingLotSkusQuery.isSuccess && <MUIDataTable
                    data={listingLotSkus}
                    columns={columnsTender}
                    options={{
                      selectableRows: 'none',
                      pagination: true,
                      jumpToPage: false,
                      rowsPerPage: 100,
                      print: false,
                      search: true,
                      download: true,
                      sort: false,
                      filter: true,
                      viewColumns: true,
                      elevation: 0,
                      components: {
                        TableFilterList: CustomFilterList
                      },
                      customToolbar: selectedRows => {
                        if (listingIsOpen) {
                          return (<>

                            <MuiToolbarSubmit disabled={!tenderBidsHaveChanges} onSubmit={() => setShowSubmitTenderBidModal(true)} />
                          </>)
                        }
                      },
                      setTableProps: () => ({ size: 'small' }),

                      onDownload: (buildHead, buildBody, columns, data) => {
                        // add prices and totals...
                        listingLotSkus.forEach((sku, index) => {
                          // copy #2
                          const tenderBid = tenderBids[sku.id]
                          const bid = isValidBid(tenderBid) ? parseInt(tenderBid) : sku.buyer_bid
                          const subtotal = (sku.qty * bid)
                          const tmp = data[index].data
                          tmp[tmp.length - 2] = bid
                          tmp[tmp.length - 1] = subtotal
                        })
                        return '\uFEFF' + buildHead(columns) + buildBody(data)
                      },

                      customTableBodyFooterRender: function(opts) {
                        // calculate total across all skus...
                        const total = listingLotSkus.reduce((accu, sku) => {
                          // get bid from tenderBids
                          // copy #1
                          const tenderBid = tenderBids[sku.id]
                          const bid = isValidBid(tenderBid) ? parseInt(tenderBid) : sku.buyer_bid
                          accu = accu + (sku.qty * bid)
                          return accu
                        }, 0)

                        return (
                          <TableFooter>
                            <TableRow>
                              {opts.selectableRows !== 'none' ? <TableCell /> : null}
                              {opts.columns.map((col, index) => {
                                if (col.display === 'true') {
                                  if (col.label === 'Subtotal') {
                                    return (<TableCell key={index} align="right"><b>{`$${parseFloat(total).toLocaleString()}`}</b></TableCell>)
                                  } else if (col.label.startsWith('Place Bid')) {
                                    return (<TableCell key={index} align="right"><b>Lot {lot_number} Total:</b></TableCell>)
                                  } else {
                                    return <TableCell key={index} />
                                  }
                                }
                                return null
                              })}
                            </TableRow>
                          </TableFooter>
                        )
                      }
                    }
                  }
                    />
            }
                  </div>
              </div>
              )
          }

          { process.env.NODE_ENV !== 'production' && <pre>isTender={JSON.stringify(isTender, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>isSkuAuction={JSON.stringify(isSkuAuction, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>listingIsOpen={JSON.stringify(listingIsOpen, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>tenderBidsAreValid={JSON.stringify(tenderBidsAreValid, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>tenderBids={JSON.stringify(tenderBids, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>saleTypeSelection={JSON.stringify(saleTypeSelection, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>awardLevelSelection={JSON.stringify(awardLevelSelection, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>isSkuAuction={JSON.stringify(isSkuAuction, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>formEnabled={JSON.stringify(formEnabled, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>listingQuery={JSON.stringify(listingQuery.data, null, 2)}</pre>}
          { process.env.NODE_ENV !== 'production' && <pre>listingLotSkus={JSON.stringify(listingLotSkus, null, 2)}</pre>}

        </div>

        {/* Bulk Bid Modal NOTE THIS IS AN EXPENSIVE COMPONENT! */}
        { isSkuAuction && <BuyerUploadBulkBidsModal
          openModal={showBulkBidModal}
          setOpenModal={setShowBulkBidModal}
          listingId={listingId}
          listingLotId={selectedLotId}
          listingLots={listingLots}
         />
        }

        {/* Bulk Upload Tender Bids. */}
        { isTender && <BuyerUploadBulkTenderBidsModal
          openModal={showTenderBulkBidModal}
          bids_mandatory_selection={bids_mandatory_selection}
          submitTenderBidMutation={submitTenderBidMutation}
          acceptTerms={acceptTerms}
          setAcceptTerms={setAcceptTerms}
          setFormSubmitted={setFormSubmitted}
          formSubmitted={formSubmitted}
          setOpenModal={setShowTenderBulkBidModal}
          globalListingNumber={global_listing_number}
          listingId={listingId}
          listingLotId={selectedLotId}
          tenderBids={tenderBids}
          setTenderBids={newTenderBids => {
            console.log('Setting tender bids', newTenderBids)
            setTenderBids(newTenderBids)
          }}
         />
        }
        <NotificationToaster
        showToast={isShowToaster}
        setShowToasFn={setIsShowToaster}
        isSuccess={true}
        message={
            'Bids Submitted '

        }
      />

        {/* Submit Tender Modal. */}
        { isTender && <BuyerSubmitTenderBidsModal
          openModal={showSubmitTenderBidModal}
          setOpenModal={setShowSubmitTenderBidModal}
          isValid={tenderBidsAreValid}
          lot_number={lot_number}
          submitTenderBidRequest={{
            listing_id: listingId,
            listing_lot_id: selectedLotId,
            bids: Object.fromEntries(listingLotSkus.map(sku => [sku.id, tenderBids[sku.id]])),
            sendMail: true
          }}
         />
        }

        {/* Submit Tender Modal. */}
        { isTender && !listingIsOpen && <BuyerTenderBiddingHasEnded
          openModal={showBiddingHasEnded}
          setOpenModal={setShowBiddingHasEnded}
         />
        }
      </>
}

export default Page
